import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Process = ({ data, buttonRef, translation }) => {
  const { title, processDetail } = data
  const { getQuoteLabel } = translation

  const handleClick = () => {
    buttonRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const stepBgImages = [
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-one.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-two.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-three.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
  ]

  return (
    <div
      className={`flex flex-wrap justify-content-center my-5 py-4`}
      style={{
        paddingLeft: "calc(100vw / 12)",
        paddingRight: "calc(100vw / 12)",
      }}
    >
      <h2 className="pt-3 ming text-center pb-3">{title}</h2>

      <div className="col-12 col-xl-10 row flex flex-wrap">
        {processDetail.map((item, index) => (
          <div key={index} className=" col-12 col-md-4 pb-4 px-5 px-md-3 ">
            <div className="flex justify-content-center">
              <div
                className={`col-3 col-sm-2 col-md-5 p-1 p-xs-0 p-md-1 p-lg-4`}
                style={{ position: "relative" }}
              >
                {stepBgImages[index]}
                <h2
                  className={`ming lora text-center`}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  {index + 1}
                </h2>
              </div>
            </div>

            <h4 className={`col-12 text-center`}>{item.title}</h4>

            <div className={`col-12 text-center`}>{item.content}</div>
          </div>
        ))}
      </div>
      <div className="flex col-12 col-lg-4 justify-content-center p-4">
        <button className="corners primaryCornersMing " onClick={handleClick}>
          {getQuoteLabel}
        </button>
      </div>
    </div>
  )
}

export default Process
