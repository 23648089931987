import React, { useRef } from "react"
import Footer from "../../components/Footer"
import ComparisonTable from "../../components/homepageV2/ComparisonTable"
import Seo from "../../components/Seo"
import { staticText } from "../../enumerator/staticText"
import {
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../../plugins/common"
import QuoteForm from "../get-a-quote/components/QuoteForm"
import Partners from "../resource-hub/components/Partners"
import FeaturedOn from "./components/FeaturedOn"

import Header from "./components/Header"
import Plan from "./components/Plan"
import Process from "./components/Process"
import ProductFeature from "./components/ProductFeature"
import Table from "./components/Table"
import Terms from "./components/Terms"
import Testimonial from "./components/Testimonial"

const Affiliate = ({ pageContext, location }) => {
  const { node, general, affiliatePage, getAQuoteV2, translation } = pageContext

  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = pageContext.general.aleaToken
  const logo = getBackgroundImage(general.allStrapiGeneral.nodes[0].footerLogo)
  const empowerDashColor = "bg-copper"
  const buttonRef = useRef(null)
  const translationData = translation.find(obj => obj.locale === lang)
  const tableData = node.rewardTable.find(obj => obj.locale === lang)

  return (
    <>
      <Seo
        meta={node.meta}
        noindex={node.meta.noindex}
        localizations={[lang]}
        location={location}
        lang={lang}
        hideLanguage={[]}
        ogImage={getBackgroundImage(node.header.logo)}
      />
      <Header data={node.header} aleaLogo={logo}></Header>
      <ProductFeature data={affiliatePage.productFeature}></ProductFeature>
      <Table data={tableData}></Table>
      <div ref={buttonRef}>
        <QuoteForm
          getAQuote={getAQuoteV2.getAQuote}
          lang={lang}
          token={aleaToken}
          location={location}
          translation={translationData.form}
          handleQuoteType={() => {}}
          type={staticText.AFFILIATE}
          affiliateForm={affiliatePage.form}
          hanndleSubmitted={false}
        />
      </div>
      <Partners data={affiliatePage.partners} lang={lang} type={"affiliate"} />
      <Plan props={affiliatePage.plan} />
      <Process
        data={affiliatePage.process}
        buttonRef={buttonRef}
        translation={translationData.form}
      />
      <Testimonial props={affiliatePage.customer_reviews} />
      <ComparisonTable lang={lang} data={affiliatePage.comparisonTable} />
      <FeaturedOn data={affiliatePage.featuredOn} />
      <Terms data={node.termAndCondition} />
      <Footer
        props={general}
        location={location}
        lang={lang}
        hideNewsletter={true}
        hideShortcuts={true}
      />
    </>
  )
}

export default Affiliate
