import { Divider } from "@mui/material"
import React from "react"
import { getBackgroundImage } from "../../../plugins/common"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const Header = ({ data, aleaLogo }) => {
  const { title, content, logo, bgImage } = data

  const bg = getBackgroundImage(bgImage)
  const affiliateLogo = getBackgroundImage(logo)

  return (
    <div
      className={"d-grid"}
      style={{
        height: "550px",
        backgroundRepeat: "repeat-x",
        //placeContent: "center",
        background: "round",
        backgroundImage: `url(${bg})`,
      }}
    >
      <div
        className={`flex flex-wrap gap-4 justify-content-center align-content-start my-0 py-0 my-md-0 py-md-3 `}
        style={{
          paddingLeft: "calc(100vw / 18)",
          paddingRight: "calc(100vw / 18)",
        }}
      >
        <div className="flex col-5 col-lg-4 justify-content-center align-content-center ">

          <img
            src={aleaLogo}
            alt=""
            className="px-0 p-lg-4"
            style={{ objectFit: "contain", maxWidth: "60%", maxHeight: "200px" }}
          />
        </div>
        <div
          className="px-0 border bg-white align-self-center"
          style={{ height: "100px" }}
        />
        <div className="flex col-5 col-lg-4 justify-content-center align-content-center ">
          <img
            src={affiliateLogo}
            alt=""
            className="px-0 p-lg-0"
            style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "200px" }}
          />
        </div>
        <div className="col-12 px-2 py-3 py-md-4">
          <h2 className="white">
            <CustomMarkdown html={title}></CustomMarkdown>
          </h2>
          <h3 className="openSans white">
            <CustomMarkdown html={content}></CustomMarkdown>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default Header
