import React from "react"

const Table = ({ data }) => {
  const { title, tableRow } = data

  return (
    <div
      className={`flex flex-wrap justify-content-center py-3`}
      style={{
        paddingLeft: "calc(100vw / 12)",
        paddingRight: "calc(100vw / 12)",
      }}
    >
      <h2 className="pt-5 ming text-center">{title}</h2>
      <div className="col-12 col-lg-10 row flex flex-wrap softy-corners table-shadow bg-ming border-ming">
        {tableRow?.map((rowItem, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {rowIndex === 0 ? (
              <>
                {rowItem.tableCol?.map((colItem, colIndex) => (
                  <h5
                    key={colIndex}
                    className={`col-6 text-center py-3 text-white`}
                  >
                    {colItem.value}
                  </h5>
                ))}
              </>
            ) : (
              <>
                {rowItem.tableCol?.map((colItem, colIndex) => (
                  <div
                    key={colIndex}
                    className={`col-6 border text-center bg-white`}
                    style={
                      tableRow.length === rowIndex + 1
                        ? {
                            borderBottomLeftRadius: `${
                              colIndex === 0 ? "15px" : "0px"
                            }`,
                            borderBottomRightRadius: `${
                              colIndex === 1 ? "15px" : "0px"
                            }`,
                          }
                        : {}
                    }
                  >
                    {colItem.value}
                  </div>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Table
