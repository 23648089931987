import React from "react"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"
import Img from "../../../components/Img"
import * as style from "./FeaturedOn.module.scss"

const FeaturedOn = ({ data, lang }) => {
  const { title, featured_medias } = data
  return (
    <div
      className={`my-5 ${style.partners}`}
      style={{
        paddingLeft: "calc(100vw / 10)",
        paddingRight: "calc(100vw / 10)",
      }}
    >
      <h2 className={`ming mb-4 text-center`}>
        <CustomMarkdown html={title} color={`copper`} />
      </h2>
      <div className={`${style.logoList} d-grid`}>
        {featured_medias.map((p, index) => (
          <Img key={index} image={p.image} className={`${style.logo} mb-3`} />
        ))}
      </div>
    </div>
  )
}

export default FeaturedOn
