import React from "react"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const Terms = ({ data }) => {
  return (
    <div
      className={`flex flex-wrap justify-content-center my-5`}
      style={{
        paddingLeft: "calc(100vw / 12)",
        paddingRight: "calc(100vw / 12)",
      }}
    >
      <CustomMarkdown html={data} classnames="content"></CustomMarkdown>
    </div>
  )
}

export default Terms
